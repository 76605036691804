<template>
  <div class="revenueRecords">
    <app-header :title="title" :isShowBack="false"></app-header>
    <div class="revenueRecords-content" v-if="isSupplierFlag">
      <!-- <div class="item">
        <div class="item-num">{{dodayOrderNum}}</div>
        <div>今日订单（个）</div>
      </div> -->
      <div class="item">
        <div class="item-num">{{dodayAccount}}</div>
        <div>今日收益（元）</div>
      </div>
      <div class="item">
        <div class="item-num">{{dodayGoodsNum}}</div>
        <div>今日售卖商品（件）</div>
      </div>
      <!-- <div class="item">
        <div class="item-num">{{allOrderNum}}</div>
        <div>累计收益订单（个）</div>
      </div> -->
      <div class="item">
        <div class="item-num">{{sumAccount}}</div>
        <div>累计收益（元）</div>
      </div>
    </div>
    <div :class="isSupplierFlag ? 'revenueRecords-list-merchant' : 'revenueRecords-list'">
      <app-list :opt="opt" @get="onGet" ref="myList"></app-list>

    </div>
    <!-- <el-dialog title="补贴提醒" :visible.sync="dialogVisible" :close-on-click-modal="false" top="32vh" width="30%">
      <div class="row">确认要从商家{{merchantName}}的保证金账户中补贴{{costAmount}}元给供应商{{supplierName}}吗?</div>
      <div class="dialog-footer">
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog> -->
    <!-- <el-dialog title="结算确认" :visible.sync="dialogVisible" width="720px" top="25vh" :close-on-click-modal="false">
      <div class="account-content">线下结算的订单，请先完成线下转账，再手动修改结算状态。确定手动结算吗？</div>
      <div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="结算给商家"></el-checkbox>
          <el-checkbox label="结算给供应商"></el-checkbox>
          <el-checkbox label="结算给经销商商"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="dialog-footer">
        <el-button type="text">取消</el-button>
        <el-button type="text" @click="submit1()">确定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
  let validItemsName = (rule, value, callback) => {
      if (value == "" || value == undefined) {
          callback();
      } else {
          //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
          let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
          if (!reg.test(value)) {
              callback(new Error("商品名称格式不对或超过32个字符"));
          } else {
              callback();
          }
      }
  };
  import cabinetUtil from "@utils/cabinetUtil.js"
  export default {
    data() {
      let _this = this;
      return {
        // dialogVisible: false,
        id: '',
        title: "供应商收益记录",
        // dialogVisible: false,
        // checkList:[],
        opt: {
          search: [{
              key: "supplierId",
              label: "供应商名称",
              type: 'remoteSearchById',
              opt: {
                list: []
              }
            },
            {
              label: "订单编号",
              key: "orderId",
              align: "center",
            },
            {
              key: "orderNo",
              label: "结算单号",
            },
            {
              key: "itemsName",
              label: "商品名称",
              rules: [{
                  validator: validItemsName,
                  trigger: ['blur']
              }]
            },
            {
              key: "itemsCode",
              label: "商品编号",
            },
            {
              key: "isPayState",
              label: "补贴状态",
              type: 'select',
              opt: {
                list: [{
                    value: 0,
                    label: '正常'
                  },
                  {
                    value: 1,
                    label: '待补贴'
                  },
                  {
                    value: 2,
                    label: '补贴中'
                  }, {
                    value: 3,
                    label: '已补贴'
                  },
                  {
                    value: 4,
                    label: '补贴失败'
                  }
                ],
              }
            },
            // {
            //   key: "close",
            //   label: "结算方式",
            //   type: 'select',
            //   opt: {
            //     list: [
            //       {
            //         value: 1,
            //         label: '线上结算'
            //       },
            //       {
            //         value: 2,
            //         label: '线下结算'
            //       }
            //     ],
            //   }
            // },
            {
              key: "state",
              label: "结算状态",
              type: 'select',
              opt: {
                list: [
                  // {
                  //   value: 0,
                  //   label: '未结算'
                  // },
                  {
                    value: 1,
                    label: '已结算'
                  },
                  {
                    value: 2,
                    label: '结算中'
                  }
                ],
              }
            },
            {
              key: "date",
              label: "结算时间",
              type: "data-range"
            },
          ],
          columns: [{
              label: "订单编号（主）",
              key: "orderId",
              align: "center",
              width: 150
            }, {
              label: "结算单号",
              key: "orderNo",
              align: "center"
            },
            {
              label: "商品名称",
              key: "itemsName",
              align: "center"
            },
            {
              label: "商品编号",
              key: "itemsCode",
              align: "center"
            },
            {
              label: "供应商名称",
              key: "supplierName",
              align: "center"
            },
             {
              label: "进货商名称",
              key: "merchantName",
              align: "center"
            },
            {
              label: "成本价",
              key: "costAmount",
              align: "center",        
            },
            {
              label: "供应商收益",
              key: "realPayAmount",
              align: "center"
            },
            {
              label: "手续费",
              key: "fee",
              align: "center"
            },
            {
              label: "支付时间",
              key: "payTime",
              align: "center"
            },
            {
              label: "结算时间",
              key: "closeTime",
              align: "center"
            },
            
            {
              label: "补贴状态",
              key: "isPayStateStr",
              align: "center"
            },
            {
              label: "补贴时间",
              key: "finishTime",
              align: "center"
            },
            // {
            //   label: "结算方式",
            //   key: "closeStateStr",
            //   align: "center"
            // },
            {
              label: "结算状态",
              key: "stateStr",
              align: "center",
              // type: "actionStatus",
              // opt: {
              //   ativeStatus: "已结算",
              //   on(row, item) {
              //     _this.dialogVisible = true;
              //   }
              // }
            },
            // {
            //   label: "操作",
            //   key: "subsidyStr",
            //   type: "actionStatus",
            //   align: "center",
            //   opt: {
            //     ativeStatus: "补贴",
            //     on(row, cb) {
            //         _this.dialogVisible = true;
            //         _this.merchantName = row.merchantName;
            //         _this.supplierName = row.supplierName;
            //         _this.costAmount = !row.costAmount ? '0.00' : Number(row.costAmount).toFixed(2);
            //         _this.id = row.id;
            //       }
            //   },
            // },
          ],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/mall-service/orders/supplier/fee/export",
            listName: '供应商收益记录'
          }],
        },
        isSupplierFlag: false,
        // 收益统计
        dodayOrderNum: 0,
        dodayAccount: 0,
        dodayGoodsNum: 0,
        allOrderNum: 0,
        sumAccount: 0,
        merchantName: '',
        supplierName: '',
        costAmount: '',
      };
    },
    created() {
      console.log("revenueRecords-list created!!");

    },
    async activated() {
      console.log("revenueRecords-list activated!!");
      try {
        this.isSupplierFlag = this.isSupplierLogin('loginType');
        if (this.isSupplierFlag) {
          this.title = '收益记录';
          this.opt.search.forEach((item, index) => {
            if (item.key == 'supplierId') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'supplierName') {
              this.opt.columns.splice(index, 1);
            }
            if (item.key == 'subsidyStr') {
              this.opt.columns.splice(index, 1);
            }
            if (item.key == 'fee') {
              this.opt.columns.splice(index, 1);
            }
          })
          this.getTotalData();
        } else {
          let merchantList = await cabinetUtil.getMerchantList();
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              item.opt.list = merchantList;
            }
          })
        }
      } catch (err) {
        console.log(error)
      }
    this.getMerchantList()
    },
    deactivated() {
      this.dialogVisible = false;
    },
    methods: {
      onGet(opt) {
        let dto = {
          roleType: 2,
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm,
          closeStartTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          closeEndTime: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") :
            null,
        };
        if (dto.date) {
          delete dto.date
        }
        this.post("/mall-service/orders/supplier/fee/page", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          res.data.list.forEach(item => {
            item.stateStr = ['未结算', '已结算', '结算中'][item.state];
            item.isPayStateStr = ['正常', '待补贴', '补贴中', '已补贴', '补贴失败'][item.isPayState];
            if(item.isPayState == 1 || item.isPayState == 4) {
              item.subsidyStr = '补贴';
            }else {
              item.subsidyStr = '-- ';//不可点击
            }
            if(item.isPayState == 1 || item.isPayState == 4) {
             item.action = [true];
            }else {
              item.action = [false];
            }
            item.finishTime = item.finishTime ? item.finishTime : '--';
            item.supplierName = item.supplierName ? item.supplierName : '--';
            item.itemsName = item.itemsName ? item.itemsName : '--';
            item.payTime = item.payTime ? item.payTime : '--';
            item.closeTime = item.closeTime ? item.closeTime : '--';
            item.subOrderNoStr = item.subOrderNo ? item.subOrderNo : '--';
            item.costAmount = !item.costAmount ? '0.00' : Number(item.costAmount).toFixed(2);
            item.realPayAmount = !item.realPayAmount ? '0.00' : Number(item.realPayAmount).toFixed(2);
            item.fee = !item.fee ? '0.00' : Number(item.fee).toFixed(2);
            var itemNameList = [];
            if (item.orderItemsRespList) {
              item.orderItemsRespList.forEach((it, idx) => {
                itemNameList.push(it.itemName)
              })
              item.itemName = itemNameList.length > 0 ? itemNameList.join('、') : '--';
            }
            if (item.itemName) {
              if (item.itemName.length > 17) {
                item.itemName = item.itemName.substr(0, 17) + '...';
              }
            } else {
              item.itemName = "--";
            }
          });
          opt.cb(res.data);
        });
      },
      // 收益统计
      getTotalData() {
        let dto = {};
        let merchantFlag = this.isSupplierLogin();
        if (merchantFlag) {
          dto.merchantId = this.cache.getLS('userInfo').merchantId;
        }
        this.post('/mall-service/v1/order/count/fee', dto, {
          isUseResponse: true
        }).then(res => {
          let data = res.data.data;
          if (res.data.code == 0) {
            // this.dodayOrderNum = data.dodayOrderNum ? data.dodayOrderNum : 0;
            this.dodayAccount = data.dodayAccount ? Number(data.dodayAccount).toFixed(2) : 0.00;
            this.dodayGoodsNum = data.dodayGoodsNum ? data.dodayGoodsNum : 0;
            // this.allOrderNum = data.allOrderNum ? data.allOrderNum : 0;
            this.sumAccount = data.sumAccount ? Number(data.sumAccount).toFixed(2) : 0.00;
          }
        })
      },
      // 确定补贴
      submit() {
        this.post('/mall-service/v1/consume/apply', {
          id: this.id
        }, {
          isUseResponse: false
        }).then(res => {
          console.log('res:',res)
          if (res.code == 0) {
            this.$message({
              message: '已发起补贴',
              type: 'success'
            })
            this.dialogVisible = false;
            this.$refs.myList.onRefresh();
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      },
      // 获取供应商列表
       getMerchantList() {
        cabinetUtil.getMerchantList({roleType: 2}).then(res => {
          let getMerchantList = res && res.length > 0 ? res : [];
          this.opt.search.forEach(item => {
            if(item.key == 'supplierId') {
              item.opt.list = res
            }
          })
        })
      },
    }
  };
</script>
<style lang="scss" scoped>
  .revenueRecords-list-merchant {
    height: calc(100% - 168px);
  }

  .revenueRecords-list {
    height: calc(100% - 60px);
  }

  .revenueRecords-content {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 12px;
  }

  .item {
    display: inline-block;
    min-width: 247px;
    height: 77px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 6px;
    margin-bottom: 10px;
    margin-right: 25px;
  }

  .item-num {
    font-size: 37px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .item-text {
    font-size: 12px;
  }

  .row {
    text-align: center;
    padding-bottom: 20px;
  }

  // .dialog-footer {
  //   text-align: center;
  // }

  /deep/.el-table td:last-child {
    padding: 0 11px;
  }
  .account-content{
    margin: -20px 0 20px;
  }
  .dialog-footer{
    text-align: right;
  }
</style>