var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "revenueRecords" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: false } }),
      _vm.isSupplierFlag
        ? _c("div", { staticClass: "revenueRecords-content" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-num" }, [
                _vm._v(_vm._s(_vm.dodayAccount))
              ]),
              _c("div", [_vm._v("今日收益（元）")])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-num" }, [
                _vm._v(_vm._s(_vm.dodayGoodsNum))
              ]),
              _c("div", [_vm._v("今日售卖商品（件）")])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-num" }, [
                _vm._v(_vm._s(_vm.sumAccount))
              ]),
              _c("div", [_vm._v("累计收益（元）")])
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          class: _vm.isSupplierFlag
            ? "revenueRecords-list-merchant"
            : "revenueRecords-list"
        },
        [
          _c("app-list", {
            ref: "myList",
            attrs: { opt: _vm.opt },
            on: { get: _vm.onGet }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }